// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
// require("jquery")
// require("jquery_nested_form")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//BEGIN GLOBAL MANDATORY SCRIPTS//

//require("admin/loader.js")
require("admin/libs/jquery-3.1.1.min.js")
require("admin/bootstrap/js/popper.min.js")
require("admin/bootstrap/js/bootstrap.min.js")
require("admin/plugins/perfect-scrollbar/perfect-scrollbar.min.js")
require("admin/app.js")

// $(document).ready(function() {
//     App.init();
// });
// require("admin/custom.js")

//END GLOBAL MANDATORY SCRIPTS//

//BEGIN PAGE LEVEL PLUGINS/CUSTOM SCRIPTS
require("admin/plugins/apex/apexcharts.min.js")
//require("admin/dashboard/dash_1.js")
//BEGIN PAGE LEVEL PLUGINS/CUSTOM SCRIPTS