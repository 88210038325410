// $(function(){
//   $('a[href^="#"]').click(function(){
//     var speed = 500;
//     var href= $(this).attr("href");
//     var target = $(href == "#" || href == "" ? 'html' : href);
//     var position = target.offset().top;
//     $("html, body").animate({scrollTop:position}, speed, "swing");
//     return false;
//   });
// });
$(document).ready(function () {
  $(".elmfaqboxsubj").on("click", function() {
    $(this).next().slideToggle();
    if ($(this).hasClass('elmfaqboxsubj-cl')) {
      $(this).removeClass('elmfaqboxsubj-cl');
      $(this).children('img').attr('src', './images/icon_hold_off.png');
    } else {
      $(this).addClass('elmfaqboxsubj-cl');
      $(this).children('img').attr('src', './images/icon_hold_on.png');
    }
  });
  $(".elmdiaryscorebar").on("click", function() {
    $(this).next().slideToggle();
    if ($(this).next().hasClass('elmdiaryscorebarblk-cl')) {
      $(this).next().removeClass('elmdiaryscorebarblk-cl');
      $(this).children('img').attr('src', './images/icon_diary_hold_off.png');
    } else {
      $(this).next().addClass('elmdiaryscorebarblk-cl');
      $(this).children('img').attr('src', './images/icon_diary_hold_on.png');
    }
  });
  // $('.popcaln').click(function(e) {
  //   e.preventDefault();
  //   popcaln();
  // });
  // $('.popclose').click(function(e) {
  //   e.preventDefault();
  //   popwinclose();
  // });
});
function popcaln() {
vurl='./pop_calendar';
window.open(vurl,"caln","width=577,height=701,top=267,left=87,scrollbars=yes,toolbar=no,menubar=no,location=no,resizable=no,status=no,directories=no");
}
function popwinclose() {
window.close();
}
